class SenderEmailsController {
  constructor(controller) {
    this.controller = controller;
    this.controller.addEventListener('click', this.handleClick.bind(this));
    this.updateRemoveButtonDisabledState()
  }

  get inputGroups() {
    return this.controller.querySelectorAll('.input-group');
  }

  handleClick(event) {
    const button = event.target;
    switch (button.dataset.toggle) {
      case 'add':
        this.handleAddEmail(button);
        break;
      case 'remove':
        this.handleRemoveEmail(button);
        break;
    }
  }

  handleAddEmail(button) {
    const clone = this.inputGroups[0].cloneNode(true);
    clone.querySelector('input').value = '';
    button.before(clone);
    this.updateRemoveButtonDisabledState();
  }

  handleRemoveEmail(button) {
    if (this.inputGroups.length === 1)
      return;

    button.closest('.input-group').remove();
    this.updateRemoveButtonDisabledState();
  }

  updateRemoveButtonDisabledState() {
    const isDisabled = this.inputGroups.length <= 1;
    this.controller.querySelectorAll('[data-toggle="remove"]').forEach(button => button.disabled = isDisabled);
  }
}

export default SenderEmailsController;
